import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import { firebase } from "../../../firebase/config";

import { TailSpin } from "react-loader-spinner";
import { FaArrowLeft, FaShippingFast } from "react-icons/fa";

const Step4Digital = ({ uid, setSection, submitClone, error }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  return (
    <MDBCol size="12">
      <div>
        <FaArrowLeft
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 0,
            marginBottom: 0,
            color: "grey",
            cursor: "pointer",
          }}
          onClick={() => setSection("Step3Digital")}
        />

        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 5,
            marginBottom: 0,
            color: "grey",
          }}
        >
          Step 3 of 3
        </p>
        <p
          style={{
            fontFamily: "SSBold",
            fontSize: 24,
            marginTop: 10,
            marginBottom: 0,
          }}
        >
          Submit Clone
        </p>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 14,
            marginTop: 0,
            marginBottom: 0,
            width: 410,
          }}
        >
          Once you click submit below we will start training your voice clone.
          Training your voice clone takes about 30 minutes and you'll be
          notified when your voice clone is ready to use.
        </p>
      </div>
      <div
        style={{
          height: 55,
          width: 410,
          backgroundColor: "blue",
          borderRadius: 7,
          marginTop: 20,
          textAlign: "center",
          fontFamily: "SSRegular",
          color: "white",
          fontSize: 18,
          paddingTop: 14,
          cursor: !loading ? "pointer" : null,
          opacity: !loading ? 1 : 0.5,
        }}
        onClick={() => {
          if (!loading) {
            setLoading(true);
            submitClone();
          }
        }}
        className="d-flex justify-content-center"
      >
        {loading ? (
          <TailSpin
            height="25"
            width="25"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{ marginTop: 0 }}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <>
            <p>Submit</p>
          </>
        )}
      </div>
      <div>
        <p style={{ color: "red", marginTop: 10 }}>{error}</p>
      </div>
    </MDBCol>
  );
};

export default withRouter(Step4Digital);
