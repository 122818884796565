import React, { useState, useEffect, useRef } from "react";
import {
  Room,
  RoomEvent,
  setLogLevel,
  VideoPresets,
  Track,
  LocalVideoTrack,
  createLocalVideoTrack,
  createLocalAudioTrack,
  RemoteVideoTrack,
  RemoteTrackPublication,
} from "livekit-client";
import { BsJoystick } from "react-icons/bs";
import { RiVoiceprintFill } from "react-icons/ri";
import { MDBRow, MDBCol } from "mdbreact";

import {
  LiveKitRoom,
  VideoConference,
  ControlBar,
  useTracks,
  TrackLoop,
  ParticipantTile,
  GridLayout,
  MediaDeviceMenu,
  MediaDeviceSelect,
  useLocalParticipant,
  useMediaDeviceSelect,
  useRoomContext,
  FocusLayout,
  LayoutContextProvider,
} from "@livekit/components-react";
import "@livekit/components-styles";
import { useLocation, useHistory } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import {
  MdCameraAlt,
  MdOutlineRecordVoiceOver,
  MdOutlineContentCopy,
  MdOutlineShare,
} from "react-icons/md";
import { Blocks, TailSpin } from "react-loader-spinner";
import { firebase } from "../../../firebase/config";
import { useDetectClickOutside } from "react-detect-click-outside";
import SidePanel from "./SidePanel";
import { useAuth } from "../Context/AuthContext";
import { startSession } from "@sentry/react";

const Call = () => {
  const { workspaceID } = useAuth();
  const [videoPlayerToken, setVideoPlayerToken] = useState("");
  const [videoPublisherToken, setVideoPublisherToken] = useState("");
  const [audioPlayerToken, setAudioPlayerToken] = useState("");
  const [audioPublisherToken, setAudioPublisherToken] = useState("");
  const [selectedAudioDevice, setSelectedAudioDevice] = useState(null);
  const [showAudioDevices, setShowAudioDevices] = useState(false);
  const [selectedAudioLabel, setSelectedAudioLabel] = useState(null);
  const [selectedCameraDevice, setSelectedCameraDevice] = useState(null);
  const [showCameraDevices, setShowCameraDevices] = useState(false);
  const [selectedCameraLabel, setSelectedCameraLabel] = useState(null);
  const [audioDevices, setAudioDevices] = useState([]);
  const [cameraDevices, setCameraDevices] = useState([]);
  const [cloneTimeLeft, setCloneTimeLeft] = useState(null);
  const [Progress, setProgress] = useState(0);
  const [roomID, setRoomID] = useState("");
  const [voiceID, setVoiceID] = useState(null);
  const [voiceType, setVoiceType] = useState(null);
  const [startedCall, setStartedCall] = useState(false);
  const [cloneID, setCloneID] = useState("");
  const [voiceSettings, setVoiceSettings] = useState({
    voiceChangerEnabled: false,
    pitchValue: 0,
  });
  const [videoSettings, setVideoSettings] = useState({
    controlEyes: false,
    controlLips: true,
    controlHeadRotation: true,
    motionSensitivity: 1,
  });
  const [videoPublishingRoom, setVideoPublishingRoom] = useState(null);
  const [audioPublishingRoom, setAudioPublishingRoom] = useState(null);
  const [hearMyselfEnabled, setHearMyselfEnabled] = useState(true);
  const [screenControl, setScreenControl] = useState(false);
  const [copied, setCopied] = useState(false);
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const ctxRef = useRef(null);

  const url = "wss://liveportrait-odb3ojth.livekit.cloud";

  useEffect(() => {
    if (cloneID && workspaceID) {
      firebase
        .firestore()
        .collection("clones")
        .doc(cloneID)
        .onSnapshot((doc) => {
          setCloneTimeLeft(doc.data().cloneTimeLeft);
        });
    }
  }, [cloneID, workspaceID]);

  useEffect(() => {
    setRoomID(query.get("roomID"));
    setVoiceID(query.get("voiceID"));
    setCloneID(query.get("cloneID"));

    setVoiceType(query.get("voiceType"));
  }, []);

  useEffect(() => {
    const handleBackButton = () => {
      // Reload the page when the user presses the back button
      window.location.reload();
    };

    // Listen for the back button event
    history.listen((location, action) => {
      if (action === "POP") {
        // "POP" indicates that the back button was pressed
        handleBackButton();
      }
    });

    return () => {
      // Cleanup the listener on component unmount
      history.listen(() => {});
    };
  }, [history]);
  // useEffect(() => {
  //   if(cloneID && workspaceID){
  //     firebase.firestore().collection("workspace").doc(workspaceID).collection("").doc(cloneID).get()
  //   }

  // }, [cloneID, workspaceID])

  useEffect(() => {
    if (roomID) {
      firebase
        .firestore()
        .collection("roomQueue")
        .doc(roomID)
        .onSnapshot((doc) => {
          if (doc.data()) {
            if (doc.data().status === "Finished") {
              history.push("/");
            }
            setProgress(doc.data().Progress);

            if (doc.data().loading === null) {
              setLoading(true);
            } else {
              setLoading(doc.data().loading);
            }

            setAudioPlayerToken(doc.data().audioPlayerAccessToken);
            setAudioPublisherToken(doc.data().audioPublisherAccessToken);
            setVideoPlayerToken(doc.data().videoPlayerAccessToken);
            setVideoPublisherToken(doc.data().videoPublisherAccessToken);
          }
        });
    }
  }, [roomID]);

  useEffect(() => {
    if (
      roomID &&
      workspaceID &&
      cloneID &&
      voiceID &&
      voiceType &&
      firebase.auth().currentUser
    ) {
      const checkAndStartSession = async () => {
        console.log("Checking if the room document exists");

        try {
          const uid = firebase.auth().currentUser.uid;
          const roomDocRef = firebase
            .firestore()
            .collection("roomQueue")
            .doc(roomID);

          // Check if the room document exists
          const roomDocSnapshot = await roomDocRef.get();
          if (roomDocSnapshot.exists) {
            console.log(
              `Room ${roomID} already exists, skipping session creation.`
            );
            return; // If the document exists, exit the function
          }

          // If the room document does not exist, proceed with starting the session
          console.log("Starting the session");
          const createSession = firebase
            .functions()
            .httpsCallable("createSession");

          // Await the function call
          const result = await createSession({
            workspaceID,
            uid,
            cloneID,
            voiceID,
            roomID,
            voiceType,
          });

          console.log(result.data);

          // Process result data
          if (result.data.status === "Success") {
            if (result.data.sessionStatus === "Pending") {
              await firebase
                .firestore()
                .collection("roomQueue")
                .doc(roomID)
                .update({
                  connected: true,
                  Progress: 30,
                });
            } else if (result.data.sessionStatus === "Active") {
              return null;
            } else if (result.data.sessionStatus === "Out of Credits") {
              return history.push("/");
            }
          }
        } catch (error) {
          console.error("Error creating room:", error);
        }
      };

      checkAndStartSession();
    }
  }, [
    roomID,
    workspaceID,
    cloneID,
    voiceID,
    voiceType,
    firebase.auth().currentUser,
  ]);
  // Update settings when they change
  useEffect(() => {
    if (videoPublishingRoom) {
      sendVideoSettings();
    }
  }, [videoSettings]);

  useEffect(() => {
    if (audioPublishingRoom && audioPublishingRoom.state === "connected") {
      sendAudioSettings();
    }
  }, [voiceSettings]);

  const sendVideoSettings = () => {
    if (videoPublishingRoom) {
      const settings = {
        sensitivity: videoSettings.motionSensitivity,
        lips: videoSettings.controlLips,
        eyes: videoSettings.controlEyes,
        rotation: videoSettings.controlHeadRotation,
      };

      const strData = JSON.stringify({ settings });
      const encoder = new TextEncoder();
      const data = encoder.encode(strData);
      videoPublishingRoom.localParticipant.publishData(data, {
        reliable: true,
        destinationIdentities: ["proxyclone-video"],
      });
    }
  };

  const sendAudioSettings = () => {
    if (audioPublishingRoom) {
      const settings = {
        pitch: voiceSettings.pitchValue,
        voiceChanger: voiceSettings.voiceChangerEnabled,
      };

      const strData = JSON.stringify({ settings });
      const encoder = new TextEncoder();
      const data = encoder.encode(strData);
      audioPublishingRoom.localParticipant.publishData(data, {
        reliable: true,
        destinationIdentities: ["proxyclone-audio"],
      });
    }
  };

  // General handler for updating settings
  const handleSettingsChange = (settingsType, key, value) => {
    if (settingsType === "videoSettings") {
      setVideoSettings((prevSettings) => ({
        ...prevSettings,
        [key]: value,
      }));
    } else if (settingsType === "voiceSettings") {
      setVoiceSettings((prevSettings) => ({
        ...prevSettings,
        [key]: value,
      }));
    }
  };

  const micRef = useDetectClickOutside({
    onTriggered: () => {
      if (showAudioDevices) {
        setShowAudioDevices(false);
      }
    },
  });

  const cameraRef = useDetectClickOutside({
    onTriggered: () => {
      if (showCameraDevices) {
        setShowCameraDevices(false);
      }
    },
  });

  useEffect(() => {
    const getAudioDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioInputDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      setAudioDevices(audioInputDevices);
    };

    getAudioDevices();
  }, []);

  useEffect(() => {
    const getCameraDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInputDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setCameraDevices(videoInputDevices);
    };

    getCameraDevices();
  }, []);

  // Handle publishing audio stream
  useEffect(() => {
    let audioTrack;
    let audioRoom;

    const publishLocalAudioStream = async (audioPublisherToken) => {
      try {
        audioRoom = new Room();
        await audioRoom.connect(url, audioPublisherToken);
        setAudioPublishingRoom(audioRoom);

        audioTrack = await createLocalAudioTrack({
          deviceId: selectedAudioDevice
            ? { exact: selectedAudioDevice }
            : undefined,
        });
        await audioRoom.localParticipant.publishTrack(audioTrack);
      } catch (e) {
        console.error("Error connecting to audio room or capturing mic", e);
      }
    };

    if (audioPublisherToken && loading === false && startedCall) {
      publishLocalAudioStream(audioPublisherToken);
    }

    // Cleanup on unmount
    return () => {
      if (audioTrack) {
        audioTrack.stop();
      }
      if (audioRoom) {
        audioRoom.disconnect();
        setAudioPublishingRoom(null);
      }
    };
  }, [audioPublisherToken, selectedAudioDevice, loading, startedCall]);

  // Handle publishing video stream
  useEffect(() => {
    let videoTrack;
    let videoRoom;

    const publishLocalVideoStream = async (videoPublisherToken) => {
      try {
        videoRoom = new Room();
        await videoRoom.connect(url, videoPublisherToken);
        setVideoPublishingRoom(videoRoom);

        const cameraStream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: selectedCameraDevice
              ? { exact: selectedCameraDevice }
              : undefined,
          },
        });

        const [track] = cameraStream.getVideoTracks();

        videoTrack = new LocalVideoTrack(track);
        await videoRoom.localParticipant.publishTrack(videoTrack);
      } catch (e) {
        console.error("Error connecting to video room or capturing camera", e);
      }
    };

    if (videoPublisherToken && loading === false && startedCall) {
      publishLocalVideoStream(videoPublisherToken);
    }

    // Cleanup on unmount
    return () => {
      if (videoTrack) {
        videoTrack.stop();
      }
      if (videoRoom) {
        videoRoom.disconnect();
        setVideoPublishingRoom(null);
      }
    };
  }, [videoPublisherToken, selectedCameraDevice, loading, startedCall]);

  useEffect(() => {
    if (
      audioPlayerToken &&
      videoPublisherToken &&
      videoPlayerToken &&
      startedCall
    ) {
      audioListener(audioPlayerToken, hearMyselfEnabled);
    }
  }, [
    audioPlayerToken,
    videoPublisherToken,
    videoPlayerToken,
    hearMyselfEnabled,
    startedCall,
  ]);

  const audioListener = async (audioPlayerToken, hearMyselfEnabled) => {
    try {
      const room = new Room();

      room.on(RoomEvent.TrackSubscribed, (track, publication, participant) => {
        if (
          track.kind === "audio" &&
          participant.identity === "proxyclone-audio"
        ) {
          if (hearMyselfEnabled) {
            const audioElement = new Audio();
            track.attach(audioElement);
            audioElement.play();
            console.log("Audio track attached and playing.");
          } else {
            console.log("Not played");
          }
        }
      });
      await room.connect(url, audioPlayerToken);
      console.log("Connected to room:", room.name);
    } catch (error) {
      console.error("Failed to connect to LiveKit room:", error);
    }
  };

  if (
    !url ||
    !audioPlayerToken ||
    !videoPublisherToken ||
    !videoPlayerToken ||
    !roomID
  ) {
    return (
      <div style={{ height: "100vh", width: "100%", backgroundColor: "black" }}>
        Loading...
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        backgroundColor: "#1e1e1e",
        overflow: "hidden",
      }}
      data-lk-theme="default"
    >
      {!loading && startedCall && (
        <SidePanel
          voiceSettings={voiceSettings}
          videoSettings={videoSettings}
          setVoiceSettings={(key, value) =>
            handleSettingsChange("voiceSettings", key, value)
          }
          setVideoSettings={(key, value) =>
            handleSettingsChange("videoSettings", key, value)
          }
          hearMyselfEnabled={hearMyselfEnabled}
          setHearMyselfEnabled={setHearMyselfEnabled}
        />
      )}
      <div style={{ flex: 1 }}>
        <video
          ref={videoRef}
          style={{ position: "absolute", left: -9999, top: -9999 }}
          autoPlay
          playsInline
          muted
          width={512}
          height={512}
        />
        <canvas
          ref={canvasRef}
          width={512}
          height={512}
          style={{ display: "none" }}
        ></canvas>

        {loading || !startedCall ? (
          <div
            style={{
              height: "100vh",
              width: "100%",
              backgroundColor: "black",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <MDBRow style={{ marginTop: "0%" }}>
              <MDBCol
                style={{ paddingBottom: 0, marginBottom: 0 }}
                className="d-flex justify-content-center"
                size="12"
              >
                <div>
                  <div
                    style={{
                      width: 600,
                      height: "10px",
                      backgroundColor: "#ccc",
                      borderRadius: "5px",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: `${Progress}%`,
                        height: "100%",
                        backgroundColor: "blue",
                        transition: "width 0.5s ease-in-out",
                      }}
                    ></div>
                  </div>
                  <p
                    style={{
                      fontFamily: "SSMedium",
                      textAlign: "center",
                      marginTop: 40,
                    }}
                  >
                    Clone is {loading ? "loading:" : "ready:"}
                  </p>
                  <ol style={{ fontFamily: "SSMedium", width: 600 }}>
                    <li style={{ marginTop: 0 }}>
                      Make sure to look ahead and close your lips and keep a
                      neutral expression before clicking start to make sure the
                      clone is synced with your expressions.
                    </li>
                    <li style={{ marginTop: 20 }}>
                      Use the controls on the left to customize the clone.
                    </li>
                    <li style={{ marginTop: 10 }}>
                      Using a voice changer causes a delay of 1-2 seconds.
                    </li>
                    <li style={{ marginTop: 10 }}>
                      You can customize the pitch higher to sound more feminine
                      and lower to sound more masculine.
                    </li>
                    <li style={{ marginTop: 10 }}>
                      You should adjust the motion sensitivity to control how
                      much you want your motion and expressions to drive the
                      clone.
                    </li>
                  </ol>
                  <div className="d-flex justify-content-center">
                    <div
                      style={{
                        height: 48,
                        background: "blue",
                        borderRadius: 11,
                        fontFamily: "SSMedium",
                        paddingTop: 12,
                        fontSize: 17,
                        cursor: "pointer",
                        color: "white",
                        paddingRight: 25,
                        paddingLeft: 25,
                        marginRight: 20,
                        marginTop: 9,
                        width: 200,
                        opacity: loading ? 0.5 : 1,
                      }}
                      onClick={() => setStartedCall(true)}
                      className="d-flex justify-content-center"
                    >
                      {loading && (
                        <TailSpin
                          height="19"
                          width="19"
                          color="#fff"
                          ariaLabel="tail-spin-loading"
                          radius="3"
                          wrapperStyle={{ marginRight: 18, marginTop: -1.5 }}
                          wrapperClass="d-inline"
                          visible={true}
                        />
                      )}
                      <p>Start Call</p>
                    </div>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </div>
        ) : (
          <LiveKitRoom
            serverUrl={url}
            token={videoPlayerToken}
            connect={true}
            video={false}
            audio={false}
            onDisconnected={() => history.push("/")}
            style={{ scrollY: "hidden" }}
          >
            <CityVideoRenderer
              setLoading={setLoading}
              setScreenControl={setScreenControl}
              screenControl={screenControl}
              voiceSettings={voiceSettings}
            />

            <div className="d-flex justify-content-center">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    height: 44,
                    width: 193,
                    marginRight: 20,
                    backgroundColor: "#1E1E1E",
                    borderRadius: 4,
                    textAlign: "center",
                    color: "white",
                    paddingTop: 8.5,
                    cursor: "pointer",
                    border: "1px solid #888888",
                    position: "relative",
                  }}
                  onClick={() => setShowCameraDevices(!showCameraDevices)}
                >
                  <MdCameraAlt
                    style={{
                      marginRight: 10,
                      marginTop: -2,
                      cursor: "pointer",
                    }}
                  />
                  <label style={{ cursor: "pointer" }}>
                    {selectedCameraLabel
                      ? selectedCameraLabel.slice(0, 13)
                      : "Camera"}
                  </label>

                  <div
                    style={{
                      width: 433,
                      position: "absolute",
                      display: showCameraDevices ? "block" : "none",
                      bottom: 50,
                      zIndex: 90000,
                      backgroundColor: "#1e1e1e",
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderRadius: 4,
                    }}
                    ref={cameraRef}
                  >
                    {cameraDevices.map((device) => {
                      const truncatedLabel =
                        device.label.length > 20
                          ? `${device.label.slice(0, 70)}...`
                          : device.label;
                      return (
                        <div
                          key={device.deviceId}
                          onClick={() => {
                            setSelectedCameraLabel(truncatedLabel);
                            setSelectedCameraDevice(device.deviceId);
                          }}
                          style={{
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingLeft: 10,
                            paddingRight: 10,
                            cursor: "pointer",
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = "#1e90ff")
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = "#1e1e1e")
                          }
                        >
                          {truncatedLabel || `Camera ${device.deviceId}`}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  style={{
                    height: 44,
                    width: 193,
                    marginRight: 0,
                    backgroundColor: "#1E1E1E",
                    borderRadius: 4,
                    textAlign: "center",
                    color: "white",
                    paddingTop: 8.5,
                    cursor: "pointer",
                    border: "1px solid #888888",
                    position: "relative",
                  }}
                  onClick={() => setShowAudioDevices(!showAudioDevices)}
                >
                  <MdOutlineRecordVoiceOver
                    style={{
                      marginRight: 10,
                      marginTop: -4.2,
                      cursor: "pointer",
                    }}
                  />
                  <label style={{ cursor: "pointer" }}>
                    {selectedAudioLabel
                      ? selectedAudioLabel.slice(0, 13)
                      : "Microphone"}
                  </label>

                  <div
                    style={{
                      width: 433,
                      position: "absolute",
                      display: showAudioDevices ? "block" : "none",
                      bottom: 50,
                      zIndex: 90000,
                      backgroundColor: "#1e1e1e",
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderRadius: 4,
                    }}
                    ref={micRef}
                  >
                    {audioDevices.map((device) => {
                      const truncatedLabel =
                        device.label.length > 20
                          ? `${device.label.slice(0, 70)}...`
                          : device.label;
                      return (
                        <div
                          key={device.deviceId}
                          onClick={() => {
                            setSelectedAudioLabel(truncatedLabel);
                            setSelectedAudioDevice(device.deviceId);
                          }}
                          style={{
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingLeft: 10,
                            paddingRight: 10,
                            cursor: "pointer",
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = "#1e90ff")
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = "#1e1e1e")
                          }
                        >
                          {truncatedLabel || `Microphone ${device.deviceId}`}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <ControlBar
                  controls={{
                    microphone: false,
                    camera: false,
                    screenShare: false, // Hide the screen share control
                    leave: true,
                  }}
                />
              </div>
            </div>
          </LiveKitRoom>
        )}
      </div>
    </div>
  );
};

const ParticipantInfo = ({ numParticipants }) => {
  return <div className="participantInfo">Participants: {numParticipants}</div>;
};

function CityVideoRenderer({ voiceSettings }) {
  // Get the tracks from useTracks
  const tracks = useTracks([
    Track.Source.Camera,
    Track.Source.ScreenShare,
    Track.Source.ScreenShareAudio,
  ]);

  console.log("The tracks are", tracks);

  // Filter out the tracks related to proxyclone-video or camera
  const finalTracks = tracks.filter((track) => {
    const isProxyCloneVideo =
      track.publication.trackName === "proxyclone-video" ||
      track.publication.source === Track.Source.Camera;
    return isProxyCloneVideo;
  });

  // Track when proxyclone-video is published
  useEffect(() => {
    finalTracks.forEach((trackPublication) => {
      if (
        trackPublication.publication instanceof RemoteTrackPublication &&
        trackPublication.publication.kind === "video" &&
        trackPublication.publication.track instanceof RemoteVideoTrack &&
        trackPublication.publication.subscribed
      ) {
        const remoteVideoTrack = trackPublication.publication.track;
        console.log("The remote video track is", remoteVideoTrack);

        if (voiceSettings.voiceChangerEnabled === true) {
          remoteVideoTrack.setPlayoutDelay(1.7);
          // Set the playout delay
        } else {
          remoteVideoTrack.setPlayoutDelay(0);
        }
      }
    });
  }, [finalTracks, voiceSettings]); // Run the effect when finalTracks changes

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh", // Parent div height 90% of viewport
      }}
    >
      <GridLayout
        style={{
          width: "1280px", // Grid layout width
          height: "720px", // Grid layout height
        }}
        tracks={finalTracks}
      >
        <ParticipantTile />
      </GridLayout>
    </div>
  );
}

export default Call;
