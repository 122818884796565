import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { BsPauseCircleFill, BsPlayCircleFill } from "react-icons/bs";
import Carousel from "./Carousel";
import { ReactComponent as Flowy } from "./modern.svg";

import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Pricing from "../Pricing/Pricing";
import QuestionsMobile from "../Dashboard/QuestionsMobile";
import MuxPlayer from "@mux/mux-player-react";
import Section3Mobile from "./Section3Mobile";
import Section4Mobile from "./Section4Mobile";
import PricingMobile from "../Pricing/PricingMobile";
import { FaArrowRight, FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import LandingGIF from "./landing-gif.gif";
import LiveRewind from "./live-rewind.mp4";
import Image1 from "./wet.webp";
import Meeting from "./hero.gif";

const numOfItems = [1, 2, 3, 4, 5, 6];
const Explainer = () => {
  const [activeAudio, setActiveAudio] = useState(null); // Track which audio is active
  const audio1 = useRef(
    new Audio(
      "https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/sample_voices%2Fsample_8.wav?alt=media&token=c3d7eb8c-687e-4509-b8a6-48a6fc563599"
    )
  ); // Placeholder MP3 URL
  const audio2 = useRef(
    new Audio(
      "https://firebasestorage.googleapis.com/v0/b/darkgenapp.appspot.com/o/sample_voices%2Fsample_3.wav?alt=media&token=d4b3f7f6-b7ea-4e11-a5b0-1edaf03bee67"
    )
  ); // Placeholder MP3 URL

  const handleAudioToggle = (audioRef, audioName) => {
    if (activeAudio === audioName) {
      // Mute the current active audio
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset the audio to start
      setActiveAudio(null); // No active audio
    } else {
      // Pause any currently playing audio
      if (activeAudio) {
        const currentAudioRef =
          activeAudio === "audio1" ? audio1.current : audio2.current;
        currentAudioRef.pause();
        currentAudioRef.currentTime = 0;
      }
      // Play the selected audio
      audioRef.current.play();
      setActiveAudio(audioName);
    }
  };

  return (
    <>
      <MDBRow
        style={{
          marginTop: 0,
          paddingTop: 0,
          paddingBottom: 100,
        }}
      >
        <MDBCol size="12">
          <p
            style={{
              fontFamily: "SSRegular",
              fontSize: 24,
              marginTop: 0,
              lineHeight: 1.2,
              color: "black",
            }}
          >
            Take full control in real-time
            <br />
            during video calls
          </p>
        </MDBCol>
        <MDBCol size="12">
          <div
            style={{
              position: "relative", // This makes the parent div relative
              width: "95vw", // Set the width to 95% of the viewport width
              height: `calc(95vw * (720 / 550))`,
              backgroundColor: "black",
              borderRadius: 25,
              marginTop: 0,
              border: "0px solid black",
              overflow: "hidden", // Ensures the video doesn’t overflow the div
              marginTop: 20,
            }}
          >
            <div
              style={{
                position: "absolute",
                backgroundColor: "grey",
                height: 30,
                width: 110,
                zIndex: 20,
                borderRadius: 100,
                marginTop: 20,
                textAlign: "center",
                paddingTop: 6,
                marginLeft: 20,
              }}
            >
              <p style={{ fontFamily: "SSMono", fontSize: 12, color: "white" }}>
                AI BODY SWAP
              </p>
            </div>
            <MuxPlayer
              streamType="on-demand"
              accent-color="blue"
              thumbnailTime={3}
              playbackId="500zu00gMoqz9qRSaKauljTKeoR5OZW8o3YJ3SF3Mdkcc"
              style={{
                position: "absolute", // Makes the video fill the parent
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: 25, // Keep this if you want rounded corners
                objectFit: "cover", // Ensures the video maintains its aspect ratio while covering the area
                width: "95vw", // Set the width to 95% of the viewport width
                height: `calc(95vw * (720 / 550))`,
                "--seek-backward-button": "none",
                "--center-controls": "none",
                "--bottom-controls": "none",
              }}
              autoplay
              loop
              muted
            />
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: "50%", // Cover the bottom 20% of the container
                background:
                  "linear-gradient(to top, rgba(0, 0, 0, 1) 1%, rgba(0, 0, 0, 0) 100%)",
                zIndex: 10, // Place the tint above the video
              }}
            >
              <MDBRow style={{ paddingLeft: 30, color: "white" }}>
                <MDBCol size="12">
                  <p
                    style={{
                      fontFamily: "SSRegular",
                      fontSize: 24,
                      marginTop: 40,
                    }}
                  >
                    AI Body Swap
                  </p>
                  <p
                    style={{
                      fontFamily: "SSRegular",
                      fontSize: 16,
                      marginTop: -6,
                      width: "90%",
                    }}
                  >
                    Control head movements, facial expressions and lipsync in
                    real-time.
                  </p>
                  <div
                    style={{
                      backgroundColor: "transparent", // Blue background color
                      color: "white", // White text color
                      fontSize: 17,
                      padding: "8px 20px",
                      borderRadius: 50, // Rounded button
                      textDecoration: "none",
                      fontFamily: "SSRegular",
                      border: "2px solid white", // Inner white border
                      height: 45,
                      width: 200,
                      cursor: "pointer",
                      marginTop: 20,
                    }}
                    className="d-flex justify-content-center"
                  >
                    <p>
                      Get started{" "}
                      <FaArrowRight
                        style={{
                          fontSize: 14,
                          marginLeft: 10,
                          marginTop: -3.5,
                        }}
                      />
                    </p>
                  </div>
                </MDBCol>
              </MDBRow>
            </div>
          </div>
        </MDBCol>
        <MDBCol size="12">
          <div
            style={{
              position: "relative", // This makes the parent div relative
              width: "95vw", // Set the width to 95% of the viewport width
              height: `calc(95vw * (720 / 550))`,
              backgroundColor: "black",
              borderRadius: 25,
              marginTop: 0,
              border: "0px solid black",
              overflow: "hidden", // Ensures the video doesn’t overflow the div
              marginTop: 20,
            }}
          >
            <div
              style={{
                position: "absolute",
                backgroundColor: "grey",
                height: 30,
                width: 180,
                zIndex: 20,
                borderRadius: 100,
                marginTop: 20,
                textAlign: "center",
                paddingTop: 6,
                marginLeft: 20,
              }}
            >
              <p style={{ fontFamily: "SSMono", fontSize: 12, color: "white" }}>
                AI VOICE CHANGER
              </p>
            </div>
            <div
              style={{
                position: "absolute",
                backgroundColor: "grey",
                height: 50,
                width: 50,
                zIndex: 20,
                borderRadius: 100,
                marginTop: 20,
                textAlign: "center",
                paddingTop: 6,
                right: 20,
                cursor: "pointer",
                color: "white",
              }}
              onClick={() => handleAudioToggle(audio1, "audio1")}
            >
              {activeAudio === "audio1" ? (
                <FaVolumeUp style={{ fontSize: 23, marginTop: 7 }} />
              ) : (
                <FaVolumeMute style={{ fontSize: 23, marginTop: 7 }} />
              )}
            </div>
            <MuxPlayer
              streamType="on-demand"
              accent-color="blue"
              thumbnailTime={3}
              playbackId="GGl02pq4u00hiBkgWzWzNpE38jDqXzDGxYHKjUNTsYYP00"
              style={{
                position: "absolute", // Makes the video fill the parent
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: 25, // Keep this if you want rounded corners
                objectFit: "cover", // Ensures the video maintains its aspect ratio while covering the area
                width: "95vw", // Set the width to 95% of the viewport width
                height: `calc(95vw * (720 / 550))`,
                "--seek-backward-button": "none",
                "--center-controls": "none",
                "--bottom-controls": "none",
              }}
              autoplay
              loop
              muted
            />
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: "50%", // Cover the bottom 20% of the container
                background:
                  "linear-gradient(to top, rgba(0, 0, 0, 1) 1%, rgba(0, 0, 0, 0) 100%)",
                zIndex: 10, // Place the tint above the video
              }}
            >
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "grey",
                  height: 50,
                  width: 50,
                  zIndex: 20,
                  borderRadius: 100,
                  marginTop: 20,
                  textAlign: "center",
                  paddingTop: 6,
                  right: 20,
                  cursor: "pointer",
                  color: "white",
                }}
                onClick={() => handleAudioToggle(audio2, "audio2")}
              >
                {activeAudio === "audio2" ? (
                  <FaVolumeUp style={{ fontSize: 23, marginTop: 7 }} />
                ) : (
                  <FaVolumeMute style={{ fontSize: 23, marginTop: 7 }} />
                )}
              </div>
              <MDBRow style={{ paddingLeft: 30, color: "white" }}>
                <MDBCol size="12">
                  <p
                    style={{
                      fontFamily: "SSRegular",
                      fontSize: 24,
                      marginTop: 40,
                    }}
                  >
                    AI Voice Changer
                  </p>
                  <p
                    style={{
                      fontFamily: "SSRegular",
                      fontSize: 16,
                      marginTop: -6,
                      width: "90%",
                    }}
                  >
                    Clone any voice and change your voice in real-time at 44khz
                    ultra-high quality audio.
                  </p>
                  <div
                    style={{
                      backgroundColor: "transparent", // Blue background color
                      color: "white", // White text color
                      fontSize: 17,
                      padding: "8px 20px",
                      borderRadius: 50, // Rounded button
                      textDecoration: "none",
                      fontFamily: "SSRegular",
                      border: "2px solid white", // Inner white border
                      height: 45,
                      width: 200,
                      cursor: "pointer",
                      marginTop: 20,
                    }}
                    className="d-flex justify-content-center"
                  >
                    <p>
                      Get started{" "}
                      <FaArrowRight
                        style={{
                          fontSize: 14,
                          marginLeft: 10,
                          marginTop: -3.5,
                        }}
                      />
                    </p>
                  </div>
                </MDBCol>
              </MDBRow>
            </div>
          </div>
        </MDBCol>
        <MDBCol className="d-flex justify-content-center" size="12">
          <img
            src={Image1}
            style={{
              borderRadius: 25, // Keep this if you want rounded corners
              position: "relative", // This makes the parent div relative
              width: "95vw",
              backgroundColor: "transparent",
              borderRadius: 25,
              marginTop: 0,
              border: "0px solid black",
              overflow: "hidden",
              marginTop: 60, // Ensures the video doesn’t overflow the div
            }}
          />
        </MDBCol>
        <MDBCol style={{ paddingLeft: 20, marginTop: 0 }} size="12">
          <p
            style={{
              fontFamily: "SSMono",
              marginTop: 30,
              color: "black",
              fontSize: 13,
            }}
          >
            Step 1
          </p>
          <p
            style={{
              fontFamily: "SSMedium",
              fontSize: 25,
              color: "black",
              marginTop: 19,
              lineHeight: 1.3,
            }}
          >
            Upload a twenty
            <br />
            second video
          </p>
          <p
            style={{
              fontFamily: "SSRegular",
              fontSize: 15,
              color: "black",
              marginTop: 0,
              lineHeight: 1.7,
              opacity: 0.7,
              width: "95vw",
            }}
          >
            Upload a 20 second video to create a custom clone. Cloning is
            instant, as soon you upload your video it's ready to start using.
          </p>
          <a
            target="_blank"
            href="https://calendly.com/jibril-proxyclone/30min"
            style={{
              backgroundColor: "#fff", // Blue background color
              color: "black", // White text color
              fontSize: 17,
              padding: "12px 50px",
              borderRadius: 50, // Rounded button
              textDecoration: "none",
              fontFamily: "SSMedium",
              border: "none", // Inner white border
              height: 65,
            }}
          >
            <p>
              Get started
              <FaArrowRight
                style={{ fontSize: 14, marginLeft: 10, marginTop: -3.5 }}
              />
            </p>
          </a>
        </MDBCol>
        <MDBCol className="d-flex justify-content-center" size="12">
          <div
            style={{
              position: "relative", // This makes the parent div relative
              width: "95vw",
              height: "80vw",
              backgroundColor: "transparent",
              borderRadius: 25,
              border: "0px solid black",
              overflow: "hidden",
              marginTop: 10, // Ensures the video doesn’t overflow the div
            }}
          >
            <MuxPlayer
              streamType="on-demand"
              accent-color="blue"
              thumbnailTime={3}
              playbackId="Ct5jp00Zpfr7LKHh00V514mNs01SBO00jizPY7akaa7CcF00"
              style={{
                position: "absolute", // Makes the video fill the parent
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: "95vw",
                borderRadius: 25, // Keep this if you want rounded corners
                objectFit: "cover", // Ensures the video maintains its aspect ratio while covering the area
                "--seek-backward-button": "none",
                "--center-controls": "none",
                "--bottom-controls": "none",
              }}
              autoplay
              loop
              muted
            />
          </div>
        </MDBCol>
        <MDBCol style={{ paddingLeft: 20, marginTop: 0 }} size="12">
          <p
            style={{
              fontFamily: "SSMono",
              marginTop: 30,
              color: "black",
              fontSize: 13,
            }}
          >
            Step 2
          </p>
          <p
            style={{
              fontFamily: "SSMedium",
              fontSize: 25,
              color: "black",
              marginTop: 19,
              lineHeight: 1.3,
            }}
          >
            Control the clone by
            <br />
            using your webcam
          </p>
          <p
            style={{
              fontFamily: "SSRegular",
              fontSize: 15,
              color: "black",
              marginTop: 0,
              lineHeight: 1.7,
              opacity: 0.7,
              width: "95vw",
            }}
          >
            The clone will copy your expressions and head movements in real-time
            using your camera feed. It will also change your voice in real-time
            to match the clone.
          </p>
          <a
            target="_blank"
            href="https://calendly.com/jibril-proxyclone/30min"
            style={{
              backgroundColor: "#fff", // Blue background color
              color: "black", // White text color
              fontSize: 17,
              padding: "12px 50px",
              borderRadius: 50, // Rounded button
              textDecoration: "none",
              fontFamily: "SSMedium",
              border: "none", // Inner white border
              height: 65,
            }}
          >
            <p>
              Get started
              <FaArrowRight
                style={{ fontSize: 14, marginLeft: 10, marginTop: -3.5 }}
              />
            </p>
          </a>
        </MDBCol>
        <MDBCol className="d-flex justify-content-center" size="12">
          <img
            src={Meeting}
            style={{
              borderRadius: 25, // Keep this if you want rounded corners
              position: "relative", // This makes the parent div relative
              width: "95vw",
              backgroundColor: "transparent",
              borderRadius: 25,
              marginTop: 0,
              border: "0px solid black",
              overflow: "hidden",
              marginTop: 20, // Ensures the video doesn’t overflow the div
            }}
          />
        </MDBCol>
        <MDBCol style={{ paddingLeft: 20, marginTop: 0 }} size="12">
          <p
            style={{
              fontFamily: "SSMono",
              marginTop: 30,
              color: "black",
              fontSize: 13,
            }}
          >
            Step 3
          </p>
          <p
            style={{
              fontFamily: "SSMedium",
              fontSize: 25,
              color: "black",
              marginTop: 19,
              lineHeight: 1.3,
            }}
          >
            Join your video meeting
            <br />
            on any platform.
          </p>
          <p
            style={{
              fontFamily: "SSRegular",
              fontSize: 15,
              color: "black",
              marginTop: 0,
              lineHeight: 1.7,
              opacity: 0.7,
              width: "95vw",
            }}
          >
            Work on Google Meets, Zoom, Microsoft Teams and any other video
            meeting platform you're using.
          </p>
          <a
            target="_blank"
            href="https://calendly.com/jibril-proxyclone/30min"
            style={{
              backgroundColor: "#fff", // Blue background color
              color: "black", // White text color
              fontSize: 17,
              padding: "12px 50px",
              borderRadius: 50, // Rounded button
              textDecoration: "none",
              fontFamily: "SSMedium",
              border: "none", // Inner white border
              height: 65,
            }}
          >
            <p>
              Get started
              <FaArrowRight
                style={{ fontSize: 14, marginLeft: 10, marginTop: -3.5 }}
              />
            </p>
          </a>
        </MDBCol>

        <MDBCol className="d-flex justify-content-center" size="12">
          <p
            style={{
              fontFamily: "SSMono",
              fontSize: 20,
              color: "black",
              textAlign: "center",
              marginTop: 30,
              lineHeight: 1.2,
            }}
          >
            Best-in-class models powered by unparalleled research
          </p>
        </MDBCol>
        <MDBCol className="d-flex justify-content-center" size="12">
          <div
            style={{
              width: "100%",
              backgroundColor: "#c4d6ff",
              marginTop: 20,
              borderRadius: 15,
              color: "black",
            }}
          >
            <MDBRow>
              <MDBCol style={{ paddingLeft: 40, paddingTop: 30 }} size="12">
                <p style={{ fontSize: 30, fontFamily: "SSMedium" }}>Raven-2</p>
                <p
                  style={{
                    fontSize: 16,
                    fontFamily: "SSMono",
                    opacity: 0.8,
                    width: "90%",
                  }}
                >
                  Mimzy’s advanced Raven-2 model generates highly realistic
                  digital replicas, complete with natural face movements and
                  expressions, accurately synchronized during live video calls.
                  <br />
                  <br />
                  Transform your live interactions with Mimzy for seamless,
                  authentic video experiences.
                </p>
                <div
                  style={{
                    backgroundColor: "transparent", // Blue background color
                    color: "black", // White text color
                    fontSize: 17,
                    padding: "8px 20px",
                    borderRadius: 50, // Rounded button
                    textDecoration: "none",
                    fontFamily: "SSRegular",
                    border: "2px solid black", // Inner white border
                    height: 45,
                    width: 200,
                    cursor: "pointer",
                    marginTop: 20,
                    opacity: 0.8,
                  }}
                  className="d-flex justify-content-center"
                >
                  <p>
                    Get started{" "}
                    <FaArrowRight
                      style={{
                        fontSize: 14,
                        marginLeft: 10,
                        marginTop: -3.5,
                      }}
                    />
                  </p>
                </div>
                <img
                  style={{ height: 300, marginTop: 48.7, marginLeft: 0 }}
                  src={LandingGIF}
                />
              </MDBCol>
            </MDBRow>
          </div>
        </MDBCol>

        <MDBCol style={{ marginTop: 60, paddingBottom: 50 }} size="12">
          {/* <PricingMobile /> */}
        </MDBCol>
        <MDBCol size="12">
          <QuestionsMobile />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(Explainer);
