import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";

import { FaArrowRight, FaCoins, FaRegCreditCard } from "react-icons/fa";

const HeadingMobile = () => {
  return (
    <MDBRow
      style={{
        color: "white",
        paddingTop: 120,
        width: "110vw",
        paddingLeft: 0,
        paddingBottom: 30,
      }}
    >
      <MDBCol
        className="d-flex justify-content-center"
        style={{ paddingLeft: "3vw", marginTop: 0 }}
        size="12"
      >
        <p
          style={{
            fontFamily: "SSBold",
            fontSize: 40,
            opacity: 1,
            lineHeight: 0.95,
            color: "black",
            textAlign: "center",
          }}
        >
          Mimzy let's you be{" "}
          <p className="d-inline" style={{ fontStyle: "italic" }}>
            anyone
          </p>{" "}
          during video calls.
        </p>
      </MDBCol>
      <MDBCol
        className="d-flex justify-content-center"
        style={{ paddingLeft: "3vw", marginTop: 16 }}
        size="12"
      >
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 20,
            marginTop: 0,
            lineHeight: 1.3,
            opacity: 0.95,
            color: "#30312c",
            textAlign: "center",
            fontWeight: "400",
          }}
        >
          Real-time voice changing and body-swap AI to appear as anyone
          <br />
          on any video calling platform.
        </p>
      </MDBCol>

      <MDBCol
        style={{
          paddingTop: 0,
          marginTop: 16,
        }}
        className="d-flex justify-content-center"
        size="12"
      >
        <NavLink
          to="/auth"
          exact
          style={{
            backgroundColor: "#4f46e5", // Blue background color
            color: "white", // White text color
            fontSize: 15,
            padding: "10px 50px",
            borderRadius: 50, // Rounded button
            textDecoration: "none",
            fontFamily: "SSMedium",
            border: "2px solid white", // Inner white border
            boxShadow: "0 0 0 3px #4f46e5", // Outer blue border effect
            height: 45,
            marginRight: -20,
          }}
          className="d-flex justify-content-center"
        >
          <p style={{ whiteSpace: "nowrap" }}>Get started</p>
        </NavLink>
        <a
          target="_blank"
          href="https://calendly.com/jibril-proxyclone/30min"
          style={{
            backgroundColor: "#fff", // Blue background color
            color: "black", // White text color
            fontSize: 14,
            padding: "12px 10px",
            borderRadius: 50, // Rounded button
            textDecoration: "none",
            fontFamily: "SSMedium",
            border: "none", // Inner white border
            height: 65,
            marginLeft: 40,
          }}
          className="d-flex justify-content-center"
        >
          <p style={{ whiteSpace: "nowrap" }}>
            Book a demo{" "}
            <FaArrowRight
              style={{ fontSize: 14, marginLeft: 10, marginTop: -3.5 }}
            />
          </p>
        </a>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <div style={{ marginTop: 20, marginLeft: 0 }}>
          <p style={{ color: "black", fontFamily: "SSMedium", opacity: 0.8 }}>
            <FaRegCreditCard style={{ marginRight: 8, marginTop: -3 }} />
            No card required{" "}
            <p className="d-inline" style={{ marginLeft: 5 }}>
              •
            </p>
            <FaCoins
              style={{ marginRight: 8, marginTop: -3, marginLeft: 10 }}
            />
            5 hours free
          </p>
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default withRouter(HeadingMobile);
