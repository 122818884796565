import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import Logo from "./mimzy-logo.png";

const DashboardFooter = () => {
  return (
    <MDBRow
      style={{
        paddingBottom: 40,
        color: "white",
        overflowX: "hidden",
        paddingTop: 0,
        borderTop: "0px solid #323033",
        backgroundColor: "white",
        width: "105vw",
        height: 600,
      }}
      size="12"
      className="d-flex justify-content-center"
    >
      <MDBRow
        style={{
          width: "1050px", // Set a fixed width for the navbar
          color: "black",
        }}
      >
        <MDBCol size="6">
          <NavLink
            style={{
              display: "inline-block",
              cursor: "pointer",
              zIndex: 4000,
            }}
            to="/"
            exact
          >
            <div
              style={{
                color: "#30312c",
                fontSize: 24,
                display: "flex",
                alignItems: "center",
                marginLeft: 0,
                fontFamily: "PPBold",
                cursor: "pointer",
                zIndex: 4000,
              }}
            >
              <img
                style={{
                  height: 27,
                  marginTop: -2.4,
                }}
                src={Logo}
              />
            </div>
          </NavLink>
          <p
            style={{
              fontFamily: "SSSemiBold",
              marginTop: 20,
              marginLeft: 0,
              fontSize: 19,
              opacity: 0.6,
            }}
          >
            support@mimzy.me
          </p>
        </MDBCol>
        <MDBCol style={{ fontFamily: "SSMedium" }} size="2">
          <p>Company</p>
          {/* <NavLink
            to="/pricing"
            exact
            style={{
              marginTop: 20,
              fontFamily: "SSRegular",
              color: "black",
            }}
          >
            <p style={{ marginTop: 20, opacity: 0.7, fontFamily: "SSRegular" }}>
              Pricing
            </p>
          </NavLink> */}
          <NavLink
            to="/faq"
            exact
            style={{
              marginTop: 20,
              fontFamily: "SSRegular",
              color: "black",
            }}
          >
            <p style={{ marginTop: 20, opacity: 0.7, fontFamily: "SSRegular" }}>
              FAQ
            </p>
          </NavLink>
          <a
            href="mailto:support@proxyclone.app"
            style={{
              marginTop: 20,
              opacity: 1,
              color: "black",
              fontFamily: "SSRegular",
            }}
          >
            Contact us
          </a>
        </MDBCol>
        <MDBCol style={{ fontFamily: "SSMedium" }} size="2">
          <p>Legal</p>
          <NavLink
            to="/privacy-policy"
            exact
            style={{
              marginTop: 20,
              opacity: 0.7,
              fontFamily: "SSRegular",
              color: "black",
            }}
          >
            <p>Privacy Policy</p>
          </NavLink>
          <NavLink
            to="/terms-of-service"
            exact
            style={{
              marginTop: 20,
              opacity: 0.7,
              fontFamily: "SSRegular",
              color: "black",
            }}
          >
            Terms of Service
          </NavLink>
        </MDBCol>
        <p
          style={{
            position: "absolute",
            marginTop: 500,
            fontFamily: "SSRegular",
            marginLeft: 20,
            opacity: 0.7,
          }}
        >
          Copyright Mimzy® 2024 5Reactions Ltd. All rights reserved
        </p>
      </MDBRow>
    </MDBRow>
  );
};

export default withRouter(DashboardFooter);
