import React, { Component, useState, useEffect } from "react";
import "../index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import DashboardFooter from "../Layout/DashboardFooter";
import { firebase } from "../../../firebase/config";

import { BiChevronDown, BiLinkAlt } from "react-icons/bi";
// import Heading from "./Heading";
import { FiArrowUpRight, FiRefreshCw, FiChevronRight } from "react-icons/fi";
import { FaChevronRight } from "react-icons/fa";

const Terms = () => {
  return (
    <>
      <div
        style={{
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            color: "#30312c",
            backgroundColor: "#fff",
          }}
        >
          <MDBCol
            style={{
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: 95,
            }}
            size="12"
          >
            <MDBRow
              style={{
                marginTop: 0,
                position: "fixed",
                backgroundColor: "white",
                zIndex: 100,
              }}
            >
              <DashboardNavBar />
            </MDBRow>
            <MDBCol
              className="d-flex justify-content-center"
              style={{ marginTop: 90 }}
              size="12"
            >
              <MDBRow
                style={{
                  maxWidth: "1050px", // Set a fixed width for the navbar
                  paddingLeft: 75,
                }}
              >
                <MDBCol size="12">
                  <p style={{ fontSize: 39, fontFamily: "SSBold" }}>
                    Term of Service
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 20,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Last Updated: 21/09/2024
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 20,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Introduction
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 15,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    Welcome to ProxyClone, an AI-powered platform designed to
                    transform your video calls in real-time. By using our
                    services, you agree to comply with these Terms of Service.
                    Please read them carefully.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 20,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Acceptance of Terms
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 15,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    By accessing or using the ProxyClone platform, you
                    acknowledge that you have read, understood, and agree to be
                    bound by these Terms of Service.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 20,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Registration and Account Security
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 15,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    1. To use ProxyClone, you must register for an account.
                  </p>
                  <p
                    style={{
                      fontSize: 15,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    2. You are responsible for maintaining the confidentiality
                    of your login credentials.
                  </p>
                  <p
                    style={{
                      fontSize: 15,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    3. You must provide accurate and complete information during
                    the registration process.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 20,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Service Plans and Payments
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 15,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    1. ProxyClone offers various plans with different features
                    and pricing.
                  </p>
                  <p
                    style={{
                      fontSize: 15,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    2. Payments are non-refundable, except as required by law or
                    as stated in our refund policy.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 20,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Service Plans and Payments
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 15,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    1. ProxyClone's AI algorithms will generate content based on
                    the information you provide.
                  </p>
                  <p
                    style={{
                      fontSize: 15,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    2. You retain all rights to the content generated for you,
                    but grant ProxyClone a license to use it for service
                    improvement.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 20,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Limitation of Liability
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 15,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    ProxyClone shall not be liable for any indirect, incidental,
                    or consequential damages, including, but not limited to,
                    loss of profits, data, or brand reputation.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 20,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Ethical AI Usage
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 15,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    We are committed to using AI in an ethical and transparent
                    manner. Our algorithms are designed to be responsible and
                    compliant with relevant regulations.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 20,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Termination
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 15,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    ProxyClone reserves the right to terminate your account and
                    access to the services for violations of these terms or any
                    illegal activities.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 20,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Changes to Terms
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 15,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    ProxyClone may update these Terms of Service at any time.
                    The updated terms will be posted on our website and
                    effective immediately upon posting.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 20,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Governing Law
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 15,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    These Terms of Service are governed by the laws of the
                    United Kingdom, without regard to its conflict of law
                    principles.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 20,
                      fontFamily: "SSBold",
                      marginTop: 20,
                    }}
                  >
                    Contact Information
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontSize: 15,
                      fontFamily: "SSMedium",
                      marginTop: 0,
                      width: "85%",
                    }}
                  >
                    For any questions about these Terms of Service, please
                    contact us at support@proxyclone.app
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCol>

            {firebase.auth().currentUser ? null : (
              <>
                {/* <Explainer />
                {/* <Process /> */}
                {/* <Steps />
                <Pricing />
                <div style={{ marginTop: 50 }}></div>
                <Questions />  */}
              </>
            )}
          </MDBCol>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(Terms);
