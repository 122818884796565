import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { FaArrowLeft } from "react-icons/fa";

const Step1 = ({ setSection, cloneName, setCloneName }) => {
  const [loading, setLoading] = useState(false);

  return (
    <MDBCol size="12">
      <div>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 5,
            marginBottom: 0,
            color: "grey",
          }}
        >
          Step 1 of 3
        </p>
        <p
          style={{
            fontFamily: "SSBold",
            fontSize: 24,
            marginTop: 10,
            marginBottom: 0,
          }}
        >
          Name your voice clone
        </p>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 14,
            marginTop: 0,
            marginBottom: 0,
            width: 400,
          }}
        >
          Give this instant clone a name.
        </p>
      </div>
      <div>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 16,
            marginTop: 16,
            marginBottom: 0,
          }}
        >
          Name
        </p>
        <input
          style={{
            height: 55,
            width: 410,
            marginLeft: 0,
            border: "1px solid rgba(155, 158, 163, 0.5)",
            borderRadius: 7,
            display: "inline-block",
            color: "black",
            marginTop: 5,
            backgroundColor: "white",
            fontFamily: "SSMedium",
            paddingLeft: 17,
            fontSize: 15,
            paddingTop: 3,
          }}
          value={cloneName}
          name="cloneName"
          onChange={(event) => setCloneName(event.target.value)}
          placeholder="Alex"
        />
      </div>
      <div
        style={{
          height: 55,
          width: 410,
          backgroundColor: "blue",
          borderRadius: 7,
          marginTop: 20,
          textAlign: "center",
          fontFamily: "SSRegular",
          color: "white",
          fontSize: 18,
          paddingTop: 14,
          cursor: "pointer",
          opacity: cloneName ? 1 : 0.5,
        }}
        onClick={() => {
          if (cloneName) {
            setSection("Step3Digital");
          }
        }}
        className="d-flex justify-content-center"
      >
        {loading ? (
          <TailSpin
            height="25"
            width="25"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{ marginTop: 0 }}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <>
            <p>Continue</p>
          </>
        )}
      </div>
    </MDBCol>
  );
};

export default withRouter(Step1);
