import React, { Component, useState, useEffect } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBCol,
  MDBRow,
} from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";
import { firebase } from "../../../firebase/config";
import { IoClose } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import disableScroll from "disable-scroll";
import Logo from "./logo.svg";

import "../index.css";
import DashboardNavBarMobile from "./DashboardNavBarMobile";

const FullScreenMobile = ({ setFullScreenOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [subscriptionActive, setSubscriptionActive] = useState("");
  const [email, setEmail] = useState("");

  let myRef = React.createRef();

  useEffect(() => {
    executeScroll();
    disableScroll.on();
  }, []);

  useEffect(() => {
    if (firebase.auth().currentUser) {
      setEmail(firebase.auth().currentUser.email);
    }
  }, []);

  const executeScroll = () => myRef.current.scrollIntoView();

  const signOut = () => {
    firebase.auth().signOut();
    window.location.reload();
  };

  return (
    <>
      <div
        style={{
          width: "100vh",
          position: "fixed",
          zIndex: 90000,
          backgroundColor: "white",
          height: "110vh",
          overflowY: "visible",
        }}
      >
        <div ref={myRef} style={{ position: "absolute", top: 0 }}></div>

        <DashboardNavBarMobile
          disableScroll={disableScroll}
          fullScreenMode={true}
        />

        <MDBRow style={{ borderTop: "0.1px solid #282828	", marginTop: 5 }}>
          <MDBCol
            style={{ borderBottom: "0.1px solid #282828", height: 49 }}
            size="12"
          >
            <a
              target="_blank"
              href="https://calendly.com/jibril-proxyclone/30min"
              exact
              onClick={() => {
                disableScroll.off();
                window.scrollTo(0, 0);
              }}
            >
              <p
                style={{
                  marginTop: 4,
                  marginLeft: "1%",
                  padding: "10px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontFamily: "PPMedium",
                  opacity: 0.4,
                  color: "black",
                }}
              >
                {firebase.auth().currentUser ? email : "Sign up to get started"}
              </p>
            </a>
          </MDBCol>
          {/* <MDBCol
            style={{ borderBottom: "0.1px solid #282828", height: 49 }}
            size="12"
          >
            <NavLink
              to="/pricing"
              exact
              onClick={() => {
                disableScroll.off();
                window.scrollTo(0, 0);
              }}
            >
              <p
                style={{
                  marginTop: 4,
                  marginLeft: "1%",
                  padding: "10px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontFamily: "PPMedium",
                  opacity: 0.7,
                  color: "black",
                }}
              >
                Pricing
              </p>
            </NavLink>
          </MDBCol> */}
          <MDBCol style={{ height: 49 }} size="12">
            <NavLink onClick={() => disableScroll.off()} to="/faq">
              <p
                style={{
                  marginTop: 4,
                  marginLeft: "1%",
                  padding: "10px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontFamily: "PPMedium",
                  opacity: 0.7,
                  color: "black",
                }}
              >
                FAQ
              </p>
            </NavLink>
          </MDBCol>
          <MDBCol style={{ height: 49 }} size="12">
            <NavLink onClick={() => disableScroll.off()} to="/privacy-policy">
              <p
                style={{
                  marginTop: 4,
                  marginLeft: "1%",
                  padding: "10px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontFamily: "PPMedium",
                  opacity: 0.7,
                  color: "black",
                }}
              >
                Privacy Policy
              </p>
            </NavLink>
          </MDBCol>

          <MDBCol style={{ height: 49 }} size="12">
            {firebase.auth().currentUser ? (
              <p
                style={{
                  marginTop: 4,
                  marginLeft: "1%",
                  padding: "10px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontFamily: "PPMedium",
                  opacity: 0.7,
                  color: "black",
                }}
                onClick={() => {
                  firebase.auth().signOut();
                  window.location.reload();
                }}
              >
                Log out
              </p>
            ) : (
              <NavLink
                onClick={() => disableScroll.off()}
                to="/terms-of-service"
                exact
              >
                <p
                  style={{
                    marginTop: 4,
                    marginLeft: "1%",
                    padding: "10px",
                    borderRadius: "4px",
                    cursor: "pointer",
                    fontSize: "16px",
                    fontFamily: "PPMedium",
                    opacity: 0.7,
                    color: "black",
                  }}
                >
                  Terms of Service
                </p>
              </NavLink>
            )}
          </MDBCol>
        </MDBRow>
      </div>
    </>
  );
};

export default FullScreenMobile;
