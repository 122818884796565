import React, { useState } from "react";
import {
  MdVolumeOff,
  MdVolumeUp,
  MdRemoveRedEye,
  MdFace,
  MdRotateRight,
  MdChevronLeft,
  MdChevronRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

const SidePanel = ({
  voiceSettings,
  videoSettings,
  setVoiceSettings,
  setVideoSettings,
  hearMyselfEnabled,
  setHearMyselfEnabled,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [sliderTooltip, setSliderTooltip] = useState(null);
  const [controlPitch, setControlPitch] = useState(false);
  const [controlSensitivity, setControlSensitivity] = useState(false);

  const handleSliderChange = (value) => {
    setSliderTooltip(value);
  };

  const handleSliderMouseUp = () => {
    setSliderTooltip(null);
  };

  return (
    <div
      style={{
        width: isCollapsed ? "40px" : "280px",
        backgroundColor: "#2e2e2e",
        color: "white",
        padding: isCollapsed ? "10px" : "20px",
        boxShadow: "2px 0 5px rgba(0, 0, 0, 0.5)",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        transition: "width 0.3s ease-in-out",
        position: "relative",
        paddingRight: 20,
        overflowY: isCollapsed ? "hidden" : "scroll",
        overflowX: "hidden",
      }}
    >
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        style={{
          position: "absolute",
          top: "0",
          right: "0px",
          height: "100%",
          backgroundColor: "#2e2e2e",
          color: "white",
          border: "none",
          padding: "10px 5px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "background-color 0.3s ease",
          zIndex: 1000,
        }}
      >
        {isCollapsed ? (
          <MdKeyboardDoubleArrowRight
            style={{ backgroundColor: "transparent" }}
            size={24}
          />
        ) : (
          <MdKeyboardDoubleArrowLeft
            style={{ backgroundColor: "transparent" }}
            size={24}
          />
        )}
      </button>
      <p style={{ fontFamily: "SSBold", fontSize: 27 }}>Clone Settings</p>
      {!isCollapsed && (
        <>
          <h3
            style={{
              fontFamily: "SSMedium",
              fontSize: 19,
              textDecoration: "underline",
              marginTop: 10,
            }}
          >
            Voice Settings
          </h3>
          <div style={{ marginBottom: "20px" }}>
            <label>Voice Changer</label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <button
                onClick={() =>
                  setVoiceSettings(
                    "voiceChangerEnabled",
                    !voiceSettings.voiceChangerEnabled
                  )
                }
                style={{
                  backgroundColor: voiceSettings.voiceChangerEnabled
                    ? "#1e90ff"
                    : "#444",
                  color: "white",
                  border: "none",
                  padding: "8px 12px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {voiceSettings.voiceChangerEnabled ? "On" : "Off"}
              </button>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <label>Hear Myself</label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <button
                onClick={() => setHearMyselfEnabled(!hearMyselfEnabled)}
                style={{
                  backgroundColor: hearMyselfEnabled ? "#1e90ff" : "#444",
                  color: "white",
                  border: "none",
                  padding: "8px 12px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {hearMyselfEnabled ? "On" : "Off"}
              </button>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <label>Pitch Control</label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
                position: "relative",
              }}
            >
              <input
                type="range"
                min="-14"
                max="14"
                step="1"
                value={voiceSettings.pitchValue}
                onChange={(e) => {
                  setControlPitch(true);
                  setControlSensitivity(false);
                  setVoiceSettings("pitchValue", Number(e.target.value));
                  handleSliderChange(e.target.value);
                }}
                onMouseUp={handleSliderMouseUp}
                style={{
                  width: "80%",
                  marginRight: "10px",
                  accentColor: "#1e90ff",
                }}
              />
              {sliderTooltip !== null && controlPitch && (
                <div
                  style={{
                    position: "absolute",
                    top: "-30px",
                    left: `calc(${
                      ((voiceSettings.pitchValue + 14) / 28) * 100
                    }% - 15px)`,
                    backgroundColor: "#1e90ff",
                    color: "white",
                    padding: "3px 6px",
                    borderRadius: "4px",
                    fontSize: "12px",
                  }}
                >
                  {sliderTooltip}
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                color: "#ccc",
                marginTop: "5px",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <div>-14</div>
                <div>Low</div>
              </div>
              <div style={{ textAlign: "center", marginRight: 20 }}>
                <div>14</div>
                <div>High</div>
              </div>
            </div>
          </div>
          <h3
            style={{
              marginTop: "20px",
              fontFamily: "SSMedium",
              fontSize: 19,
              textDecoration: "underline",
              marginTop: 10,
            }}
          >
            Video Controls
          </h3>
          <div style={{ marginBottom: "20px" }}>
            <label>Control Eyes</label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <button
                onClick={() =>
                  setVideoSettings("controlEyes", !videoSettings.controlEyes)
                }
                style={{
                  backgroundColor: videoSettings.controlEyes
                    ? "#1e90ff"
                    : "#444",
                  color: "white",
                  border: "none",
                  padding: "8px 12px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {videoSettings.controlEyes ? "On" : "Off"}
              </button>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <label>Control Lips</label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <button
                onClick={() =>
                  setVideoSettings("controlLips", !videoSettings.controlLips)
                }
                style={{
                  backgroundColor: videoSettings.controlLips
                    ? "#1e90ff"
                    : "#444",
                  color: "white",
                  border: "none",
                  padding: "8px 12px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {videoSettings.controlLips ? "On" : "Off"}
              </button>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <label>Control Head Rotation</label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <button
                onClick={() =>
                  setVideoSettings(
                    "controlHeadRotation",
                    !videoSettings.controlHeadRotation
                  )
                }
                style={{
                  backgroundColor: videoSettings.controlHeadRotation
                    ? "#1e90ff"
                    : "#444",
                  color: "white",
                  border: "none",
                  padding: "8px 12px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {videoSettings.controlHeadRotation ? "On" : "Off"}
              </button>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <label>Motion Sensitivity</label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
                position: "relative",
              }}
            >
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={videoSettings.motionSensitivity}
                onChange={(e) => {
                  setControlPitch(false);
                  setControlSensitivity(true);
                  setVideoSettings("motionSensitivity", Number(e.target.value));
                  handleSliderChange(e.target.value);
                }}
                onMouseUp={handleSliderMouseUp}
                style={{
                  width: "80%",
                  marginRight: "10px",
                  accentColor: "#1e90ff",
                }}
              />
              {sliderTooltip !== null && controlSensitivity && (
                <div
                  style={{
                    position: "absolute",
                    top: "-30px",
                    left: `calc(${
                      videoSettings.motionSensitivity * 100
                    }% - 15px)`,
                    backgroundColor: "#1e90ff",
                    color: "white",
                    padding: "3px 6px",
                    borderRadius: "4px",
                    fontSize: "12px",
                  }}
                >
                  {sliderTooltip}
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                color: "#ccc",
                marginTop: "5px",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <div>0</div>
                <div>Low</div>
              </div>
              <div style={{ textAlign: "center", marginRight: 20 }}>
                <div>1</div>
                <div>High</div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SidePanel;
