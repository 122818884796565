import React, { useEffect, useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { TiTick } from "react-icons/ti";
import PriceHeading from "./PriceHeading";
import { MdOutlineOutlinedFlag } from "react-icons/md";
import {
  AiTwotoneExperiment,
  AiOutlinePlus,
  AiOutlineMinus,
} from "react-icons/ai";
import { FaRegSmile } from "react-icons/fa";
import { CgShutterstock } from "react-icons/cg";
import { RiUserVoiceLine } from "react-icons/ri";
import { TbTimelineEvent } from "react-icons/tb";
import { BsLightningCharge } from "react-icons/bs";
import { LiaCloneSolid } from "react-icons/lia";
import { IoCodeOutline } from "react-icons/io5";
import { useAuth } from "../Context/AuthContext";
import { firebase } from "../../../firebase/config";
import { TailSpin } from "react-loader-spinner";

const Pricing = () => {
  const { workspaceID, fullTimeClones, interviewClones } = useAuth();
  const [subscriptionUpdated, setSubscriptionUpdated] = useState(false);
  const [currentFullTimeClones, setCurrentFullTimeClones] = useState(
    Number(fullTimeClones)
  );
  const [currentInterviewClones, setCurrentInterviewClones] = useState(
    Number(interviewClones)
  );
  const [totalPrice, setTotalPrice] = useState(0);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setTotalPrice(currentFullTimeClones * 8370 + currentInterviewClones * 2500);
    if (
      currentFullTimeClones !== Number(fullTimeClones) ||
      currentInterviewClones !== Number(interviewClones)
    ) {
      setUnchanged(false);
    } else {
      setUnchanged(true);
    }
  }, [currentFullTimeClones, currentInterviewClones]);

  const startSubscription = async () => {
    if (!unchanged) {
      if (fullTimeClones > 0 || interviewClones > 0) {
        setSubscriptionLoading(true);
        const updateSubscription = firebase
          .functions()
          .httpsCallable("updateSubscription");
        updateSubscription({
          fullTimeClones: currentFullTimeClones,
          interviewClones: currentInterviewClones,
          workspaceID,
        })
          .then(async (result) => {
            if (result.data.status === "success") {
              // We have a Stripe Checkout URL, let's redirect.
              setSubscriptionLoading(false);
              setSubscriptionUpdated(true);
              setUnchanged(true);

              setTimeout(() => {
                setSubscriptionUpdated(false);
              }, 5000);
            }
          })
          .catch((e) => {
            setError(e);
            setSubscriptionLoading(false);
          });
      } else {
        setSubscriptionLoading(true);

        const createCheckoutLink = firebase
          .functions()
          .httpsCallable("generateStripeSubscriptionCheckoutLink");
        createCheckoutLink({
          fullTimeClones: currentFullTimeClones,
          interviewClones: currentInterviewClones,
          workspaceID,
        })
          .then(async (result) => {
            if (result.data.url) {
              // We have a Stripe Checkout URL, let's redirect.
              window.location.assign(result.data.url);
            }
          })
          .catch((e) => {
            setError(e);
            setSubscriptionLoading(false);
          });
      }
    }
  };

  const openBillingPortal = async () => {
    const openPortal = firebase
      .functions()
      .httpsCallable("generateStripePortalLink");
    const { data } = await openPortal();
    window.location.assign(data.url);
  };

  return (
    <>
      <MDBRow style={{ marginTop: 20 }}>
        <MDBCol className="d-flex justify-content-center" size="12">
          <p style={{ fontFamily: "SSBold", fontSize: 36 }}>
            How many clones do you need?
          </p>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="d-flex justify-content-center" size="12">
          <p
            onClick={() => openBillingPortal()}
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
              marginTop: 0,
              fontFamily: "SSMedium",
            }}
          >
            Manage your subscriptions and billing details
          </p>
        </MDBCol>
      </MDBRow>
      <MDBRow
        style={{ marginTop: 20 }}
        className="d-flex justify-content-center"
      >
        <MDBCol className="d-flex justify-content-center" size="12">
          <MDBRow
            className="d-flex justify-content-center"
            style={{
              width: 1120,
              marginTop: 0,
              color: "#30312c",
              paddingRight: 0,
              paddingLeft: 0,
              marginLeft: -20,
              userSelect: "none",
            }}
          >
            <MDBCol className="d-flex justify-content-center" size="6">
              <div
                style={{
                  paddingLeft: 20,
                  fontFamily: "PPMedium",
                  paddingLeft: 20,
                  width: 577,
                  paddingTop: 20,
                  borderRadius: 12,
                  border: "1px solid rgb(234, 236, 240)",
                  backgroundColor: "#fff",
                  height: 250,
                }}
              >
                <p style={{ fontFamily: "SSMedium", fontSize: 24 }}>
                  Interview Clone - 2500 INR per month
                </p>
                <p
                  style={{
                    fontFamily: "SSRegular",
                    fontSize: 15,
                    marginTop: -13,
                  }}
                >
                  Interview clones can be used up to{" "}
                  <p style={{ fontFamily: "SSBold" }} className="d-inline">
                    2 hours a month.
                  </p>
                </p>
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "70%" }}
                >
                  <div
                    style={{
                      height: 60,
                      width: 60,
                      borderRadius: 2000,
                      border: "1px solid blue",
                      fontSize: 30,
                      color: "blue",
                      paddingTop: 13,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (currentInterviewClones > 0) {
                        setCurrentInterviewClones((prev) => prev - 1);
                      }
                    }}
                    className="d-flex justify-content-center"
                  >
                    <AiOutlineMinus />
                  </div>
                  <p
                    style={{
                      fontSize: 30,
                      fontFamily: "SSMedium",
                      marginTop: 5,
                    }}
                  >
                    {currentInterviewClones} clones
                  </p>
                  <div
                    style={{
                      height: 60,
                      width: 60,
                      borderRadius: 2000,
                      border: "1px solid blue",
                      fontSize: 30,
                      color: "blue",
                      paddingTop: 13,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setCurrentInterviewClones((prev) => prev + 1)
                    }
                    className="d-flex justify-content-center"
                  >
                    <AiOutlinePlus />
                  </div>
                </div>
                <div style={{ marginTop: 20 }}>
                  <p
                    style={{
                      fontFamily: "SSMedium",
                      fontSize: 28,
                      marginTop: 30,
                      marginLeft: 5,
                    }}
                    className="d-inline"
                  >
                    ₹{currentInterviewClones * 2500}{" "}
                    <p
                      className="d-inline"
                      style={{
                        fontFamily: "SSRegular",
                        fontSize: 14,
                        opacity: 0.7,
                      }}
                    >
                      / month
                    </p>
                  </p>
                </div>
              </div>
            </MDBCol>
            {/* <MDBCol className="d-flex justify-content-center" size="6">
              <div
                style={{
                  paddingLeft: 20,
                  fontFamily: "PPMedium",
                  paddingLeft: 20,
                  width: 577,
                  paddingTop: 20,
                  borderRadius: 12,
                  border: "1px solid rgb(234, 236, 240)",
                  backgroundColor: "#fff",
                  height: 250,
                }}
              >
                <p style={{ fontFamily: "SSMedium", fontSize: 24 }}>
                  Full-time Clone - 8370 INR per month
                </p>
                <p
                  style={{
                    fontFamily: "SSRegular",
                    fontSize: 15,
                    marginTop: -13,
                  }}
                >
                  Full-time clones can be used up to{" "}
                  <p style={{ fontFamily: "SSBold" }} className="d-inline">
                    40 hours a month.
                  </p>
                </p>
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "70%" }}
                >
                  <div
                    style={{
                      height: 60,
                      width: 60,
                      borderRadius: 2000,
                      border: "1px solid blue",
                      fontSize: 30,
                      color: "blue",
                      paddingTop: 13,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (currentFullTimeClones > 0) {
                        setCurrentFullTimeClones((prev) => prev - 1);
                      }
                    }}
                    className="d-flex justify-content-center"
                  >
                    <AiOutlineMinus />
                  </div>
                  <p
                    style={{
                      fontSize: 30,
                      fontFamily: "SSMedium",
                      marginTop: 5,
                    }}
                  >
                    {currentFullTimeClones} clones
                  </p>
                  <div
                    style={{
                      height: 60,
                      width: 60,
                      borderRadius: 2000,
                      border: "1px solid blue",
                      fontSize: 30,
                      color: "blue",
                      paddingTop: 13,
                      cursor: "pointer",
                    }}
                    onClick={() => setCurrentFullTimeClones((prev) => prev + 1)}
                    className="d-flex justify-content-center"
                  >
                    <AiOutlinePlus />
                  </div>
                </div>
                <div style={{ marginTop: 20 }}>
                  <p
                    style={{
                      fontFamily: "SSMedium",
                      fontSize: 28,
                      marginTop: 30,
                      marginLeft: 5,
                    }}
                    className="d-inline"
                  >
                    ₹{currentFullTimeClones * 8370}{" "}
                    <p
                      className="d-inline"
                      style={{
                        fontFamily: "SSRegular",
                        fontSize: 14,
                        opacity: 0.7,
                      }}
                    >
                      / month
                    </p>
                  </p>
                </div>
              </div>
            </MDBCol> */}
            <MDBCol className="d-flex justify-content-center" size="12">
              <div
                style={{
                  height: 60,
                  width: 540,
                  backgroundColor: subscriptionUpdated ? "green" : "blue",
                  marginTop: 20,
                  borderRadius: 7,
                  paddingTop: 18,
                  color: "white",
                  fontFamily: "SSMedium",
                  fontSize: 19,
                  opacity:
                    totalPrice === 0 || subscriptionLoading || unchanged
                      ? 0.6
                      : 1,
                  cursor:
                    totalPrice === 0 || subscriptionLoading || unchanged
                      ? null
                      : "pointer",
                }}
                onClick={() => {
                  if (
                    !subscriptionLoading &&
                    totalPrice > 0 &&
                    !unchanged &&
                    !subscriptionUpdated
                  ) {
                    startSubscription();
                  }
                }}
                className="d-flex justify-content-center"
              >
                {subscriptionLoading ? (
                  <TailSpin
                    height="30"
                    width="30"
                    color="blue"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{ marginTop: -3 }}
                    wrapperClass=""
                    visible={true}
                  />
                ) : subscriptionUpdated ? (
                  <p>Subscription updated!</p>
                ) : totalPrice === 0 ? (
                  <p>Choose your number of clones</p>
                ) : unchanged ? (
                  <p>Current plan</p>
                ) : (
                  <p>Subscribe - ₹{totalPrice} per month</p>
                )}
              </div>
            </MDBCol>
            <MDBCol size="12" className="d-flex justify-content-center">
              <p
                style={{ marginTop: 15, color: "red", fontFamily: "SSRegular" }}
              >
                {error
                  ? "There was an error with your payment information. Update your details by clicking the blue link above."
                  : null}
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default Pricing;
