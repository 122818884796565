import React, { useState, useRef, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { FaPlay, FaPause } from "react-icons/fa6";
import { TailSpin } from "react-loader-spinner";
import { FaArrowLeft } from "react-icons/fa";

const Step3Digital = ({
  setSection,
  voiceID,
  setVoiceID,
  voices,
  isOpen,
  setVoiceType,
}) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [playingVoice, setPlayingVoice] = useState(null); // Track currently playing voice
  const audioRef = useRef(null); // Reference to the audio element

  const handlePlayPause = (voice) => {
    if (playingVoice === voice) {
      // If the same voice is clicked again, pause it
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setPlayingVoice(null);
    } else {
      // If a different voice is clicked, play it
      if (audioRef.current) {
        audioRef.current.pause(); // Pause the currently playing voice
      }
      const newAudio = new Audio(voice.sample);
      newAudio.play();
      audioRef.current = newAudio; // Set the new audio as the reference
      setPlayingVoice(voice);
    }
  };

  useEffect(() => {
    if (!isOpen && audioRef) {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setPlayingVoice(null);
    }
  }, [isOpen, audioRef]);

  return (
    <MDBCol size="12">
      <div>
        <FaArrowLeft
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 0,
            marginBottom: 0,
            color: "grey",
            cursor: "pointer",
          }}
          onClick={() => setSection("Step2Digital")}
        />

        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 5,
            marginBottom: 0,
            color: "grey",
          }}
        >
          Step 3 of 4
        </p>
        <p
          style={{
            fontFamily: "SSBold",
            fontSize: 24,
            marginTop: 10,
            marginBottom: 0,
          }}
        >
          Choose a voice
        </p>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 14,
            marginTop: 0,
            marginBottom: 0,
            width: 400,
          }}
        >
          Choose a high quality voice for your clone.
        </p>
      </div>
      <div style={{ height: 250, overflowY: "scroll", overflowX: "hidden" }}>
        {voices.map((voice) => {
          return (
            <div
              key={voice.name}
              style={{
                height: 65,
                width: 410,
                borderRadius: 3,
                marginTop: 20,
                fontFamily: "SSRegular",
                color: "black",
                paddingTop: 10,
                cursor: "pointer",
                opacity: voice.voiceID === voiceID ? 1 : 0.6,
                border:
                  voice.voiceID === voiceID
                    ? "2px solid blue"
                    : "2px solid #eaecf0",
                paddingLeft: 50,
                backgroundColor: "transparent",
              }}
              onClick={() => {
                handlePlayPause(voice);
                setVoiceID(voice.voiceID);
                const voiceType =
                  voice.type === "Clone" ? "Clone" : "Professional";

                setVoiceType(voiceType);
              }}
              className="content"
            >
              <MDBRow style={{ width: 410 }}>
                {playingVoice === voice ? (
                  <FaPause
                    style={{
                      display: "inline-block",
                      fontSize: 30,
                      marginTop: 7,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePlayPause(voice);
                    }}
                  />
                ) : (
                  <FaPlay
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePlayPause(voice);
                    }}
                    style={{
                      display: "inline-block",
                      fontSize: 30,
                      marginTop: 7,
                    }}
                  />
                )}
                <div style={{ marginLeft: 25 }}>
                  <p style={{ fontFamily: "SSSemiBold", fontSize: 20 }}>
                    {voice.name}
                  </p>
                  <p
                    style={{
                      fontFamily: "SSRegular",
                      fontSize: 13,
                      marginTop: -20,
                    }}
                  >
                    {voice.type === "Clone"
                      ? "Cloned voice"
                      : `Professional voice`}
                  </p>
                </div>
              </MDBRow>
            </div>
          );
        })}
      </div>
      <div
        style={{
          height: 55,
          width: 410,
          backgroundColor: "blue",
          borderRadius: 7,
          marginTop: 20,
          textAlign: "center",
          fontFamily: "SSRegular",
          color: "white",
          fontSize: 18,
          paddingTop: 14,
          cursor: "pointer",
          opacity: voiceID ? 1 : 0.5,
        }}
        onClick={() => {
          if (voiceID) {
            if (audioRef.current) {
              audioRef.current.pause();
            }
            setPlayingVoice(null);
            setSection("Step4Digital");
          }
        }}
        className="d-flex justify-content-center"
      >
        {loading ? (
          <TailSpin
            height="25"
            width="25"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{ marginTop: 0 }}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <p>Continue</p>
        )}
      </div>
    </MDBCol>
  );
};

export default Step3Digital;
